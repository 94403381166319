import L from 'leaflet';
import 'leaflet-textpath';
import './lv-icon';
import isEqual from 'lodash.isequal';
import { areSegmentsEqual } from './segment-isequal';

const osmUrl = process.env.ELM_APP_OPEN_STREET_MAP_URI;
const osmAttrib = 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a>';
const unAssignedColor = '#b0302d';
const defaultBorderColor = '#6f6e6e';

customElements.define(
  'leaflet-map',
  class LeafletMap extends HTMLElement {
    constructor() {
      super();
      this._onSegmentSelected = this._onSegmentSelected.bind(this);
      this._onMoved = this._onMoved.bind(this);
      this._map = null;
      this._doZoom = false;
    }

    connectedCallback() {
      this._map = L.map(this);

      L.tileLayer(osmUrl, { attribution: osmAttrib }).addTo(this._map);
      L.control.scale().addTo(this._map);
      this._streetPartsLayers = L.featureGroup().addTo(this._map);
      this._streetPartMarkers = L.featureGroup();
      this._map
        .on('zoom', () => {
          if (this._map.getZoom() >= 16) {
            this._map.addLayer(this._streetPartMarkers);
          } else {
            this._map.removeLayer(this._streetPartMarkers);
          }
        })
        .on('moveend', () => {
          this._onMoved(this._map.getBounds());
        });
      this._initialize();
      this._update();
    }

    get segments() {
      return this._segments;
    }
    set segments(value) {
      if (areSegmentsEqual(value, this._segments)) {
        return;
      }
      this._segments = value;
      this._initialize();
      this._update();
    }

    get segmentState() {
      return this._segmentState;
    }
    set segmentState(value) {
      if (isEqual(value, this._segmentState)) {
        return;
      }
      this._segmentState = value;
      this._update();
    }

    get highlightedSegments() {
      return this._highlightedSegments;
    }
    set highlightedSegments(value) {
      if (isEqual(value, this._highlightedSegments)) {
        return;
      }
      this._highlightedSegments = value;
      this._update();
    }

    get focusedSegment() {
      return this._focusedSegment;
    }
    set focusedSegment(value) {
      if (isEqual(value, this._focusedSegment)) {
        return;
      }
      this._focusedSegment = value;
      this._update();
    }

    get streetParts() {
      return this._streetParts;
    }
    set streetParts(value) {
      if (isEqual(value, this._streetParts)) {
        return;
      }
      this._streetParts = value;
      this._update();
    }

    get zoom() {
      return this._zoom;
    }
    set zoom(value) {
      if (isEqual(value, this._zoom)) {
        return;
      }
      this._zoom = value;
      this._doZoom = true;
      this._update();
    }

    _onSegmentSelected(segmentId) {
      this.dispatchEvent(
        new CustomEvent('segmentselected', { detail: segmentId })
      );
    }

    _onMoved(newBounds) {
      const body = {
        detail: {
          northEast: {
            latitude: newBounds.getNorthEast().lat,
            longitude: newBounds.getNorthEast().lng
          },
          southWest: {
            latitude: newBounds.getSouthWest().lat,
            longitude: newBounds.getSouthWest().lng
          }
        }
      };
      this.dispatchEvent(new CustomEvent('moved', body));
      this._resetStyle();
    }

    _update() {
      if (!this._map) {
        return;
      }
      this._updateSegments();
      this._highlightSegments();
      this._showSegmentTooltip();
      this._showStreetParts();
      if (this._doZoom) {
        this._doZoom = false;
        this._zoomToSetBounds();
      }
    }

    _initialize() {
      if (!this._map) {
        return;
      }
      if (this._segmentsLayer) {
        this._map.removeLayer(this._segmentsLayer);
      }
      this._hideStreetParts();
      this._segmentsLayer = L.geoJson(
        this._toGeoJson(this._segments),
        this._options()
      ).addTo(this._map);
    }

    _zoomToSetBounds() {
      if (!this._zoom) {
        return;
      }
      if (this._zoom === 'streets') {
        this._fitToStreetParts();
      } else if (this._zoom === 'all') {
        this._fitToAllSegments();
      } else {
        const newBounds = this._zoom;
        this._map.fitBounds(
          L.latLngBounds(
            L.latLng(
              newBounds.northEast.latitude,
              newBounds.northEast.longitude
            ),
            L.latLng(
              newBounds.southWest.latitude,
              newBounds.southWest.longitude
            )
          )
        );
      }
    }

    _resetStyle() {
      if (!this._segmentState) {
        return;
      }
      this._segmentsLayer.eachLayer(function(layer) {
        this._segmentsLayer.resetStyle(layer);
      }, this);
    }

    _fitToAllSegments() {
      this._map.fitBounds(this._segmentsLayer.getBounds());
    }

    _toGeoJson(segments) {
      const features = segments
        .map(segment => {
          const feature = this._toFeature(segment);
          feature.properties = {
            segment: segment.id
          };
          if (!feature.geometry) {
            console.log('No geometry in contour for', segment);
            return null;
          }
          if (feature.geometry.type_ !== undefined) {
            feature.geometry.type = feature.geometry.type_;
            delete feature.geometry.type_;
          }
          return feature;
        })
        .filter(s => s !== null);
      return {
        type: 'FeatureCollection',
        features: features
      };
    }

    _toFeature(segment) {
      if (segment.contour.type === 'Feature') {
        return segment.contour;
      } else {
        return {
          type: 'Feature',
          geometry: segment.contour
        };
      }
    }

    _toProperties(layer) {
      if (!!layer.feature && !!layer.feature.properties) {
        return layer.feature.properties;
      } else if (!!layer.options && !!layer.options.properties) {
        return layer.options.properties;
      } else {
        console.log('Can not get properties of ', layer);
        return null;
      }
    }

    _updateSegments() {
      if (!this._segmentsLayer) {
        console.log(
          'WARN: trying to update the segments, but no segments layer present!'
        );
        return;
      }
      if (!this._segmentState) {
        return;
      }
      this._segmentsLayer.eachLayer(function(layer) {
        const properties = this._toProperties(layer);
        this._segmentState.forEach(function(state) {
          if (properties.segment == state.id) {
            properties.label = state.name;
            properties.duration = state.duration;
            properties.demandAmount = state.demandAmount;
            properties.paradiseId = state.paradiseId;
            properties.deliverer = state.deliverer.id;
            properties.color = state.deliverer.color;
            properties.delivererLabel = state.deliverer.delivererLabel;
          }
        });
        this._segmentsLayer.resetStyle(layer);
      }, this);
    }

    _highlightSegments() {
      if (!this._segmentsLayer) {
        console.log(
          'WARN: trying to hightlight a segment, but no segments layer present!'
        );
        return;
      }
      const segmentsToHighLight = Array.isArray(this._highlightedSegments)
        ? this._highlightedSegments
        : [];
      this._segmentsLayer.eachLayer(function(layer) {
        if (segmentsToHighLight.includes(this._toProperties(layer).segment)) {
          this._highlightLayer(layer);
        } else {
          this._segmentsLayer.resetStyle(layer);
        }
      }, this);
    }

    _showSegmentTooltip() {
      if (!this._segmentsLayer) {
        console.log(
          'WARN: trying to hightlight a segment, but no segments layer present!'
        );
        return;
      }
      this._segmentsLayer.eachLayer(function(layer) {
        if (this._toProperties(layer).segment == this._focusedSegment) {
          layer.openTooltip();
        } else {
          layer.closeTooltip();
        }
      }, this);
    }

    _highlightLayer(layer) {
      layer.setStyle({
        weight: 5,
        opacity: 1,
        fillOpacity: 0.5,
        iconOptions: {
          weight: 45
        }
      });
    }

    _options() {
      return {
        style: this._styleSegment.bind(this),
        onEachFeature: (feature, layer) => {
          layer.on(
            'mouseover',
            e => {
              const layer = e.target;
              this._highlightLayer(layer);

              if (!L.Browser.ie && !L.Browser.opera) {
                // layer.bringToFront();
              }
            },
            this
          );
          layer.on(
            'mouseout',
            e => {
              this._segmentsLayer.resetStyle(e.target);
            },
            this
          );
          layer.on(
            'click',
            e => {
              this._onSegmentSelected(this._toProperties(e.target).segment);
            },
            this
          );
          layer.bindTooltip(
            layer => {
              return this._segmentDetails(this._toProperties(layer));
            },
            { direction: 'right' }
          );
        },
        pointToLayer: (geoJsonPoint, latlng) => {
          return L.marker.lvMarker(latlng, {
            iconOptions: {},
            properties: geoJsonPoint.properties
          });
        }
      };
    }

    _styleSegment(feature) {
      const opacity = this._map.getZoom() >= 16 ? 0.1 : 1;
      if (!feature) {
        return;
      }
      if (!feature.properties.deliverer) {
        return {
          weight: 2,
          opacity: opacity,
          color: defaultBorderColor,
          fillOpacity: 0.8,
          fillColor: unAssignedColor,
          iconOptions: {
            color: unAssignedColor,
            borderColor: defaultBorderColor,
            weight: 20
          }
        };
      } else {
        const color = feature.properties.color;
        return {
          weight: 2,
          opacity: opacity,
          color: defaultBorderColor,
          fillOpacity: 0.4,
          fillColor: color,
          iconOptions: {
            color: color,
            borderColor: defaultBorderColor,
            weight: 20
          }
        };
      }
    }

    _showStreetParts() {
      this._hideStreetParts();
      if (!this._streetParts || this._streetParts.length == 0) {
        return;
      }
      this._streetPartsLayers.addLayer(
        L.geoJson(
          this._streetPartsToGeoJson(this._streetParts),
          this._streetPartOptions()
        )
      );
    }

    _hideStreetParts() {
      this._streetPartsLayers.clearLayers();
      this._streetPartMarkers.clearLayers();
    }

    _fitToStreetParts() {
      if (this._streetPartsLayers.getLayers().length > 0) {
        this._map.fitBounds(this._streetPartsLayers.getBounds());
      }
    }

    _streetPartsToGeoJson(streetParts) {
      const features = streetParts.map(streetPart => {
        return {
          type: 'Feature',
          properties: {
            index: streetPart.index
          },
          geometry: {
            type: 'LineString',
            coordinates: streetPart.postalAddresses.map(postalAddress => [
              postalAddress.coordinates['longitude'],
              postalAddress.coordinates['latitude']
            ])
          }
        };
      });
      return {
        type: 'FeatureCollection',
        features: features
      };
    }

    _streetPartOptions() {
      const streetPartMarkers = this._streetPartMarkers;
      return {
        style: {
          color: '#007DEF',
          weight: 1
        },
        onEachFeature: (feature, layer) => {
          if (feature.properties.index) {
            streetPartMarkers.addLayer(
              L.marker(layer.getBounds().getCenter(), {
                icon: L.divIcon({
                  className: 'street-part-index',
                  html: feature.properties.index,
                  iconSize: [16, 16]
                })
              })
            );
            layer.setText('> ', {
              repeat: true,
              offset: 5,
              attributes: {
                'font-weight': 'normal',
                'font-size': '20',
                fill: '#007DEF'
              }
            });
          }
        }
      };
    }

    _segmentDetails(properties) {
      const div = document.createElement('div');
      if (!properties) {
        return div;
      }

      div.appendChild(document.createTextNode(properties.label + ' '));

      if (properties.demandAmount) {
        const secondLine = document.createElement('div');
        div.appendChild(secondLine);
        secondLine.appendChild(
          document.createTextNode(' Oplage: ' + properties.demandAmount)
        );
      }

      if (properties.paradiseId) {
        const lastLine = document.createElement('div');
        div.appendChild(lastLine);
        lastLine.appendChild(
          document.createTextNode(' Nr: ' + properties.paradiseId)
        );
      }
        
      const nameLine = document.createElement('div');
      nameLine.appendChild(this._svgClock(properties.duration));
      div.appendChild(nameLine);
      nameLine.appendChild(
        document.createTextNode('  ' +properties.delivererLabel)
      );
      nameLine.style.color = properties.color
      
      return div;
    }



    _svgClock(time) {
      const clock = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      );
      clock.setAttributeNS(null, 'width', '15px');
      clock.setAttributeNS(null, 'height', '15px');
      clock.setAttributeNS(null, 'viewBox', '0 0 100 100');
      clock.setAttributeNS(null, 'preserveAspectRatio', 'xMidYMid meet');
      clock.appendChild(this._circleForClock());
      if (time > 0) {
        clock.appendChild(this._dialForClock(this._radiansForClock(time)));
      }
      return clock;
    }

    _circleForClock() {
      const circle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
      );
      circle.setAttributeNS(null, 'cx', 50);
      circle.setAttributeNS(null, 'cy', 50);
      circle.setAttributeNS(null, 'r', 49);
      circle.setAttributeNS(null, 'stroke', 'black');
      circle.setAttributeNS(null, 'stroke-width', 3);
      circle.setAttributeNS(null, 'fill', 'none');
      return circle;
    }

    _dialForClock(radians) {
      if (Math.abs(radians - 2 * Math.PI) < 0.001) {
        const fullCircle = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'circle'
        );
        fullCircle.setAttributeNS(null, 'cx', 50);
        fullCircle.setAttributeNS(null, 'cy', 50);
        fullCircle.setAttributeNS(null, 'r', 49);
        fullCircle.setAttributeNS(null, 'stroke', 'black');
        fullCircle.setAttributeNS(null, 'fill', 'black');
        return fullCircle;
      } else {
        const dial = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        const x = 50 + 50 * Math.sin(radians);
        const y = 50 - 50 * Math.cos(radians);
        const flag = radians < Math.PI ? 0 : 1;
        const path = [
          'M',
          50,
          50,
          'L',
          50,
          0,
          'A',
          50,
          50,
          0,
          flag,
          1,
          x,
          y,
          'L',
          50,
          50
        ].join(' ');
        dial.setAttributeNS(null, 'd', path);
        dial.setAttributeNS(null, 'stroke', 'black');
        dial.setAttributeNS(null, 'fill', 'black');
        return dial;
      }
    }

    _radiansForClock(time) {
      const roundedTime = Math.ceil(time / 5) * 5;
      const clampedTime = Math.max(0, Math.min(roundedTime, 60));
      const degrees = clampedTime * 6;
      const radians = (degrees * Math.PI) / 180;
      return radians;
    }
  }
);
