// The LVMarler is a marker that has a SVG map marker, where the color can be set.

const tinycolor = require('tinycolor2');

const defaultFontColor = '#6f6e6e';

// The LVIcon
L.DivIcon.LVIcon = L.DivIcon.extend({
  options: {
    className: 'svg-icon',
    color: 'rgba(0,0,0,0)',
    borderColor: '#6f6e6e',
    weight: 2,
    iconSize: L.point(32, 48),
    iconAnchor: null,
    popupAnchor: null
  },
  initialize: function(options) {
    L.Util.setOptions(this, options);

    if (!options.iconSize) {
      options.iconSize = L.point(40, 60);
    }
    this.options.iconSize = L.point(options.iconSize);

    if (!this.options.iconAnchor) {
      this.options.iconAnchor = L.point(
        this.options.iconSize.x / 2,
        this.options.iconSize.y
      );
    } else {
      this.options.iconAnchor = L.point(this.options.iconAnchor);
    }

    if (!this.options.popupAnchor) {
      this.options.popupAnchor = L.point(0, -0.75 * this.options.iconSize.y);
    } else {
      this.options.popupAnchor = L.point(this.options.popupAnchor);
    }

    this.options.html = this._createSvg();
    this.updateSvgStyle();
  },
  _createSvg: () => {
    const xmlns = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(xmlns, 'svg');
    svg.setAttribute('viewBox', '0 0 512 512');

    const drop = document.createElementNS(xmlns, 'path');
    drop.setAttribute(
      'd',
      'M256,0C153,0,70,83,70,185c0,127,166,313,173,321 c7,7,18,7,25,0c7-8,173-194,173-321C441,83,358,0,256,0z'
    );
    drop.setAttribute('fill-opacity', 0.6);
    svg.appendChild(drop);

    const text = document.createElementNS(xmlns, 'text');
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('x', '256');
    text.setAttribute('y', '256');
    text.setAttribute('font-size', '160');
    text.setAttribute('font-weight', 'bold');
    text.setAttribute('fill', '#6f6e6e');
    const txt = document.createTextNode('LV');
    text.appendChild(txt);
    svg.appendChild(text);

    return svg;
  },
  updateSvgStyle: function() {
    const svg = this.options.html;
    svg.setAttribute('class', 'svg-icon');
    svg.setAttribute('width', this.options.iconSize.x);
    svg.setAttribute('height', this.options.iconSize.y);

    const drop = svg.children[0];
    drop.setAttribute('fill', this.options.color);
    drop.setAttribute('stroke', this.options.borderColor);
    drop.setAttribute('stroke-width', this.options.weight);

    const text = svg.children[1];
    text.setAttribute(
      'fill',
      tinycolor
        .mostReadable(this.options.color, [defaultFontColor], {
          includeFallbackColors: true
        })
        .toHexString()
    );
  }
});

L.divIcon.lvIcon = function(options) {
  return new L.DivIcon.LVIcon(options);
};

L.Marker.LVMarker = L.Marker.extend({
  options: {
    iconFactory: L.divIcon.lvIcon,
    iconOptions: {}
  },
  initialize: function(latlng, options) {
    options = L.Util.setOptions(this, options);
    options.icon = options.iconFactory(options.iconOptions);
    this._latlng = latlng;
  },
  onAdd: function(map) {
    L.Marker.prototype.onAdd.call(this, map);
  },
  setStyle: function(style) {
    if (!this.options.icon || !style.iconOptions) {
      return;
    }
    for (optn in style.iconOptions) {
      this.options.iconOptions[optn] = style.iconOptions[optn];
      this.options.icon.options[optn] = style.iconOptions[optn];
    }
    this.options.icon.updateSvgStyle();
  }
});

L.marker.lvMarker = function(latlng, options) {
  return new L.Marker.LVMarker(latlng, options);
};
