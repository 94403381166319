class MarkdownEditor extends HTMLElement {
    constructor() {
        super();
    }

    /* Called when Ckeditor Editor indicates a changed value. Dispatches a change event on the DOM element, which Elm listens to */
    onChange() {
        if ( this._editor.model.document.differ.getChanges().length > 0 ) {
            this.dispatchEvent(new CustomEvent('change', { detail: this._editor.getData()}))
        }
    }

    initializeEditor() {
        if (!this._editor) {
            this._element = document.createElement('div');
            this._element.id = 'editor';
            this.appendChild(this._element);

            let initialData = ''
            if (this.hasAttribute('initialvalue')) {
                initialData = this.getAttribute('initialvalue')
            }

            ClassicEditor
                .create(document.querySelector('#editor'), 
                    { initialData: initialData
                    , toolbar :
                            [ 'heading'
                            , 'bold'
                            , 'italic'
                            , '|'
                            , 'link'
                            , 'bulletedList'
                            , 'numberedList'
                            , '|'
                            , 'outdent'
                            , 'indent'
                            , '|'
                            , 'blockQuote'
                            , 'undo'
                            , 'redo']
                    
                })
                .catch(error => {
                    console.error(error);
                })
                .then(editor => {
                    this._editor = editor
                    editor.model.document.on('change:data', this.onChange.bind(this))
                })
        }
    }

    /* Called when tag <markdown-editor> is added to DOM */
    connectedCallback() {
        if (typeof ClassicEditor == 'undefined') {
            var s = document.createElement('script');
            s.setAttribute('src', './ckeditor.js');
            s.onload = this.initializeEditor.bind(this)
            document.body.appendChild(s);
        }
        else {
            this.initializeEditor()
        }
    }

    /* Called when tag <markdown-editor> is removed from the DOM */
    disconnectedCallback() {
        if (!this._editor) {
            return;
        }
        this._editor.off('change:data');
        this._editor.destroy()
    }
}
customElements.define('markdown-editor', MarkdownEditor);
