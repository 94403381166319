'use strict';

/**
 * Get the security codes that are stored.
 */
export function getSecurityCodes() {
    const securityCodes = {
        statePrefix: window.localStorage.getItem(STATE_PREFIX_NAME) || createStatePrefix(),
        code: window.localStorage.getItem(CODE_NAME) || createCode()
    };
    store(securityCodes);
    return securityCodes;
}

/**
 * Refresh the security codes and return the new codes.
 */
export function refreshSecurityCodes() {
    const securityCodes = createSecurityCodes();
    store(securityCodes);
    return securityCodes;
}

function store(securityCodes) {
    window.localStorage.setItem(STATE_PREFIX_NAME, securityCodes.statePrefix);
    window.localStorage.setItem(CODE_NAME, securityCodes.code);
}

function createSecurityCodes() {
    return {
        statePrefix: createStatePrefix(),
        code: createCode()
    }
}

function createStatePrefix() {
    return createRandomBytesInBase64(15)
}

function createCode() {
    return createRandomBytesInBase64(64)
}

function createRandomBytesInBase64(size) {
    const buffer = new Uint8Array(size);
    crypto.getRandomValues(buffer);
    return btoa(String.fromCharCode(...buffer));
}

const STATE_PREFIX_NAME = 'state-prefix@dpg';
const CODE_NAME = 'code@dpg';
