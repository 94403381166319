import isEqual from 'lodash.isequal';

export function areSegmentsEqual(value, other) {
  const s1 = toSegmentDeliverer(value);
  const s2 = toSegmentDeliverer(other);
  return isEqual(s1, s2);
}

function toSegmentDeliverer(segments) {
  const sd = {};
  if (!segments) {
    return sd;
  }
  segments.forEach(function(s) {
    sd[s.id] = true;
  });
  return sd;
}
