
let wakeLock = null

export function wakeLockPorts(app) {
    app.ports.wakeLock.subscribe(function () {
        if (navigator.wakeLock && wakeLock === null) {
            navigator.wakeLock.request('screen').then(sentinal => wakeLock = sentinal);
        }
    });

    app.ports.wakeLockRelease.subscribe(function () {
        if (wakeLock !== null) {
            wakeLock.release()
        }
    });
}
