'use strict';

import 'better-dom/dist/better-dom.min';
import 'better-dateinput-polyfill/dist/better-dateinput-polyfill.min';
import './UI/segment-map/css/leaflet.css';
import './Style/bootstrap.min.css';
import { Elm } from './Main.elm';
import './UI/segment-map';
import './Demand/aggregated.css';
import './Demand/summary.css';
import './Payment/detail.css';
import './Payment/annual.css';
import './Payment/overview.css';
import './Lists/Distribution/distribution.css';
import './Lists/RouteDetail/routedetail.css';
import './Lists/Ride/summary.css';
import './Count/hub-packs.css';
import './Lists/Received/checklist.css';
import './Style/main.css';
import { getSecurityCodes, refreshSecurityCodes } from './Security';
import './UI/MarkdownEditor/MarkdownEditor'
import './UI/MarkdownEditor/MarkdownEditor.css'
import {wakeLockPorts} from './Portal/WakeLock'

/** The Elm app.
 * Use it to configure the ports.
 * For more info:
 * https://hackernoon.com/how-elm-ports-work-with-a-picture-just-one-25144ba43cdd
 */
const app = Elm.Main.init({ flags: config() });

/**
 * Get the configuration.
 */
function config() {
  const value = configFromEnv();
  value.securityCodes = getSecurityCodes();
  value.window = windowSize();
  value.moment = moment();
  value.selectedDP = localStorage.getItem('selected-dp');
  return value;
}

/**
 * Get the configuration from the environment variables.
 */
function configFromEnv() {
  return {
    uris: {
      authentication: process.env.ELM_APP_AUTHORIZATION_ENDPOINT,
      logout: process.env.ELM_APP_LOGOUT_ENDPOINT,
      token: process.env.ELM_APP_TOKEN_ENDPOINT
    },
    clientId: process.env.ELM_APP_CLIENT_ID,
    environment: process.env.ELM_APP_ENVIRONMENT
  };
}

/**
 * Get the size of the window for responsiveness.
 */
function windowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
}

/**
 * Get the current moment, in milliseconds
*/
function moment() {
  return new Date().getTime();
}

function localStorageGet(key) {
    return localStorage.getItem(key);
}


function localStorageSet(arg) {
    localStorage.setItem(arg.key, arg.value);
}

function rememberSelectedDP(name) {
    return function(value) {
        localStorage.setItem(name, value);
    }
}

app.ports.rememberSelectedDP.subscribe(rememberSelectedDP('selected-dp'));
app.ports.rememberValue.subscribe(localStorageSet);
app.ports.rememberedValue.subscribe(function(key) {
    const value = localStorageGet(key)
    if (value !== null) {
        app.ports.gotRememberedValue.send({key: key, value: value});
    }
});
app.ports.refreshSecurityCodes.subscribe(function() {
  app.ports.securityCodesRefreshed.send(refreshSecurityCodes());
});
wakeLockPorts(app)


/**
 * Event listeners needed to make drag and drop work.
 */
document.body.addEventListener('dragstart', event => {
  if (event.target && event.target.draggable) {
    // absurdly, this is needed for Firefox; see https://medium.com/elm-shorts/elm-drag-and-drop-game-630205556d2
    event.dataTransfer.setData('text/html', 'blank');
  }
});
document.body.addEventListener('dragover', () => {
  // this is needed in order to make dragging work
  return false;
});


setupDraggable(app.ports.dragEvents.send);

function setupDraggable(sendEvent) {
    const BEACON_ATTRIBUTE = 'data-beacon';
    const SCROLL_BEACON_ATTRIBUTE = 'scroll-beacon';
    const MINIMUM_DRAG_DISTANCE_PX = 100;

    document.addEventListener('pointerdown', awaitDragStart);

    function awaitDragStart(startEvent) {
        document.addEventListener('pointermove', maybeDragMove);
        document.addEventListener('pointerup', stopAwaitingDrag);

        function stopAwaitingDrag() {
            document.removeEventListener('pointermove', maybeDragMove);
            document.removeEventListener('pointerup', stopAwaitingDrag);
        }

        function maybeDragMove(moveEvent) {
            const dragDistance = distanceSquared(coords(startEvent), coords(moveEvent));
            if (dragDistance >= MINIMUM_DRAG_DISTANCE_PX) {
                dragEvent('start', startEvent);
                dragEvent('move', moveEvent);
                stopAwaitingDrag();
                document.addEventListener('pointermove', dragMove);
                document.addEventListener('pointerup', dragEnd);
            }
        }
    }

    function dragEnd(event) {
        dragEvent('stop', event);
        document.removeEventListener('pointermove', dragMove);
        document.removeEventListener('pointerup', dragEnd);
    }

    function dragMove(event) {
        dragEvent('move', event);
    }

    function dragEvent(type, event) {
        sendEvent({
            type: type,
            cursor: coords(event),
            beacons: beaconPositions(BEACON_ATTRIBUTE),
            scrollBeacons: beaconPositions(SCROLL_BEACON_ATTRIBUTE),
        });
    }

    function beaconPositions(beaconAttr) {
        const beaconElements = document.querySelectorAll(`[${beaconAttr}]`);
        return Array.from(beaconElements).map(beaconDataForAttr(beaconAttr));
    }

    function beaconDataForAttr(beaconAttr) {
        return elem => {
            const boundingRect = elem.getBoundingClientRect();
            const beaconId = elem.getAttribute(beaconAttr);
            return {
                id: tryParse(beaconId),
                x: boundingRect.x,
                y: boundingRect.y,
                width: boundingRect.width,
                height: boundingRect.height
            };
        };
    }

    function tryParse(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return str;
        }
    }

    function coords(event) {
        return { x: event.clientX, y: event.clientY };
    }

    function distanceSquared(pos1, pos2) {
        const dx = pos1.x - pos2.x;
        const dy = pos1.y - pos2.y;
        return dx*dx+dy*dy;
    }
}
